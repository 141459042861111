import { find } from "../utils/dom";
import IMask from "imask";

const Contato = () => {
	const page = find("[js-page='contato']");
	if (!page) return;


	const BASEURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
	const CONTENTFORM = find('[js-content-form]');
	const CONTATOLOAD = find('[js-contato-load]');

	function is_fieldNull(dados) {
		return (!dados) ? true : false;
	}


	const observer = new MutationObserver((mutationsList) => {
		mutationsList.forEach((mutation) => {
			if (mutation.type === 'childList') {
				CONTENTFORM?.classList.remove('not_visibled');
				CONTATOLOAD?.classList.add('loaded');
				return;
			}
		})
	})

	const config = {
		attributes: true,
		childList: true,
		subtree: true
	};


	const formRDContato = find('[js-form-contato-rd]');

	observer.observe(formRDContato, config);

	document.addEventListener('rd-form-success', function (event) {
		console.log("Formulário enviado com sucesso!");
	})
	/*
	
		const formContato = find('#wf-form-Contact');
	
		formContato?.addEventListener("submit", (e) => {
			e.preventDefault();
	
			const dados = new FormData(e.target);
	
			const messageError = find('[js-error-message]');
			const messageSuccess = find('[js-success-message]');
			const buttonClosedSuccess = messageSuccess.querySelector('.close-button');
	
			messageError.classList.remove('is_visible');
	
			const dadosForm = {
				"nome": dados.get('Contact-Name'),
				"telefone": dados.get('Contact-Phone'),
				"beneficio": dados.get('Benefit-Category'),
				"portadorDoenca": dados.get('Health-Category'),
				"email": dados.get('Contact-Email'),
				"message": dados.get('Contact-Message'),
			}
	
			let countErros = 0;
			Object.entries(dadosForm).forEach(([key, value]) => {
				if (is_fieldNull(value)) {
					countErros++;
					messageError.classList.add('is_visible');
					return;
				}
			});
	
			if (countErros == 0) {
				messageSuccess.classList.add('is_visible');
			}
	
	
			buttonClosedSuccess?.addEventListener("click", () => {
				messageSuccess.classList.remove('is_visible');
			})
	
	
	
	
		})
	
	
		const fieldsFormContato = formContato.querySelectorAll('input');
		fieldsFormContato.forEach((input) => {
	
	
			switch (input.type) {
				case 'tel':
					IMask(input, {
						mask: "(00) 00000-0000"
					});
					break;
			}
		})
	*/

	const screenMobile = window.matchMedia('(max-width: 990px)');

	if (screenMobile.matches) {
		window.location.href = `${BASEURL}#w-node-d15efeed-5242-799c-6d90-cd6cf88e7112-383a66df`
	}
}

export default Contato;